











import { defineComponent } from "@vue/composition-api";
import PageLayout from "@/components/PageLayout.vue";

export default defineComponent({
  name: "Certificates",
  components: {
    PageLayout,
    ConsumerCertificates: () => import("@/components/consumer/ConsumerCertificates.vue"),
  },
  computed: {
    organization() {
      return this.$store.state.user.organization;
    },
  },
});
